import { LayoutView } from '@/components/layout/LayoutView';
import { NavbarView } from '@/components/navbar/NavbarView';
import { useConversationStore } from '@/components/store/ConversationStore';
import { VStack } from '@/components/utilities/VStack';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export default function IndexPage() {
  const router = useRouter();
  const characters = useConversationStore(state => state.characters);
  const characterIds = Object.keys(characters);
  const firstCharacterId = characterIds[0];
  useEffect(() => {
    if (firstCharacterId) {
      router.push(`/c/${firstCharacterId}`);
    }
  }, [firstCharacterId]);
  return <LayoutView data-sentry-element="LayoutView" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx">
      <VStack className="w-full h-full overflow-hidden" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
        <NavbarView data-sentry-element="NavbarView" data-sentry-source-file="index.tsx" />
        <VStack className="w-full h-full overflow-hidden" data-sentry-element="VStack" data-sentry-source-file="index.tsx" />
      </VStack>
    </LayoutView>;
}